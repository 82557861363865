import React from 'react'
import ModalImage from "react-modal-image";

const WorkPopup = ({smallImg, largeImg, alt, id}) => {
    const closeLightbox = () => {
        this.state.open = true;
    };
    // console.log("WorkPop", smallImg, largeImg, alt)
    return (
        <div className={`popupImg-${id}`}>
              <ModalImage
              small={smallImg}
              large={largeImg}
              alt={alt}
              onClose={closeLightbox}
            />
        </div>
      )
}

export default WorkPopup