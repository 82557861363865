import React, { useEffect } from 'react'
import $ from 'jquery';
// import counterUp from 'jquery.counterup'
const HeaderComponent = () => {

  useEffect(() => {

    //===== Prealoder

    $(window).on('load', function (event) {
      // $('.preloader').delay(500).fadeOut(500);
    });

    const scrollLink = $('.page-scroll');
        // Active link switching
        $(window).scroll(function() {
        const scrollbarLocation = $(this).scrollTop();

        scrollLink.each(function() {

          const sectionOffset = $(this.hash).offset().top - 73;

          if ( sectionOffset <= scrollbarLocation ) {
            $(this).parent().addClass('active');
            $(this).parent().siblings().removeClass('active');
          }
        });
    });


    //===== Progress Bar
    
    //   if($('.progress-line').length){
    //     $('.progress-line').appear(function(){
    //         var el = $(this);
    //         var percent = el.data('width');
    //         $(el).css('width',percent+'%');
    //     },{accY: 0});
    // }
  
  
  //===== Counter Up
  
  // $('.counter').counterUp({
  //     delay: 10,
  //     time: 1600,
  // });


  },[]);
  return (
    <div>
      {/* <!--====== PRELOADER PART START ======--> */}

      <div className="preloader">
        <div className="loader_34">
          <div className="ytp-spinner">
            <div className="ytp-spinner-container">
              <div className="ytp-spinner-rotator">
                <div className="ytp-spinner-left">
                  <div className="ytp-spinner-circle"></div>
                </div>
                <div className="ytp-spinner-right">
                  <div className="ytp-spinner-circle"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!--====== PRELOADER ENDS START ======--> */}

      {/* <!--====== HEADER PART START ======--> */}

      <header id="home" className="header-area">
        <div className="navigation fixed-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg">
                  <a href="" className="heading-portfolio">My Portfolio</a>
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                  </button>

                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                      <li className="nav-item active"><a className="page-scroll" href="#home">Home</a></li>
                      <li className="nav-item"><a className="page-scroll" href="#about">About</a></li>

                      <li className="nav-item"><a className="page-scroll" href="#work">Portfolio</a></li>

                      <li className="nav-item"><a className="page-scroll" href="#contact">Contact</a></li>
                    </ul>
                  </div>
                  {/* <!-- navbar collapse --> */}
                </nav>
                {/* <!-- navbar --> */}
              </div>
            </div>
            {/* <!-- row --> */}
          </div>
          {/* <!-- container --> */}
        </div>
        {/* <!-- navigation --> */}

        <div id="parallax" className="header-content d-flex align-items-center">
          <div className="header-shape shape-one layer" data-depth="0.10">
            <img src="assets/images/banner/shape/shape-1.png" alt="Shape" />
          </div>
          {/*  */}
          <div className="header-shape shape-tow layer" data-depth="0.30">
            <img src="assets/images/banner/shape/shape-2.png" alt="Shape" />
          </div>
          {/*  */}
          <div className="header-shape shape-three layer" data-depth="0.40">
            <img src="assets/images/banner/shape/shape-3.png" alt="Shape" />
          </div>
          <div className="header-shape shape-fore layer" data-depth="0.60">
            <img src="assets/images/banner/shape/shape-2.png" alt="Shape" />
          </div>
          <div className="header-shape shape-five layer" data-depth="0.20">
            <img src="assets/images/banner/shape/shape-1.png" alt="Shape" />
          </div>
          <div className="header-shape shape-six layer" data-depth="0.15">
            <img src="assets/images/banner/shape/shape-4.png" alt="Shape" />
          </div>
          <div className="header-shape shape-seven layer" data-depth="0.50">
            <img src="assets/images/banner/shape/shape-5.png" alt="Shape" />
          </div>
          <div className="header-shape shape-eight layer" data-depth="0.40">
            <img src="assets/images/banner/shape/shape-3.png" alt="Shape" />
          </div>
          <div className="header-shape shape-nine layer" data-depth="0.20">
            <img src="assets/images/banner/shape/shape-6.png" alt="Shape" />
          </div>
          <div className="header-shape shape-ten layer" data-depth="0.30">
            <img src="assets/images/banner/shape/shape-3.png" alt="Shape" />
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-5 col-lg-6">
                <div className="header-content-right">
                  <h4 className="sub-title">Hello, I’m</h4>
                  <h1 className="title">Mohd Shahbaz</h1>
                  <p>A Web Developer</p>
                  <a className="main-btn" href="#work">View my Work</a>
                </div>
                {/* <!-- header content right --> */}
              </div>
              <div className="col-lg-6 offset-xl-1">
                <div className="header-image d-none d-lg-block">
                  <img src="assets/images/codeblur.jpg" alt="code" />
                </div>
                {/* <!-- header image --> */}
              </div>
            </div>
            {/* <!-- row --> */}
          </div>
          {/* <!-- container --> */}
          <div className="header-social">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="header-social-icon">
                    <ul>
                      <li><a href="https://facebook.com/mohdshahbaz.khan.397" target="_blank"><i className="lni-facebook-filled"></i></a></li>
                      <li><a href="https://www.instagram.com/shahbaz432sk/" target="_blank"><i className="lni-instagram"></i></a></li>
                      <li><a href="https://www.linkedin.com/in/mohd-shahbaz-5796b6127" target="_blank"><i className="lni-linkedin-original"></i></a></li>
                    </ul>
                  </div>
                  {/* <!-- header social --> */}
                </div>
              </div>
              {/* <!-- row --> */}
            </div>
            {/* <!-- container --> */}
          </div>
          {/* <!-- header social --> */}
        </div>
        {/* <!-- header content --> */}
      </header>

      {/* <!--====== HEADER PART ENDS ======--> */}

    </div>
  )
}

export default HeaderComponent