import React from 'react'

const FooterComponent = () => {
  return (
    <div>
      {/* <!--====== FOOTER PART START ======--> */}

      <footer id="footer" className="footer-area">
        <div className="footer-widget pt-130 pb-130">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="footer-content text-center">
                  <h2 className="footer-social-text">
                    You can contact me also on my social media account
                  </h2>
                  <p className="mt-"></p>
                  <ul>
                    <li><a href="https://facebook.com/mohdshahbaz.khan.397" target="_blank"><i className="lni-facebook-filled"></i></a></li>
                    <li><a href="https://www.instagram.com/shahbaz432sk/" target="_blank"><i className="lni-instagram"></i></a></li>
                    <li><a href="https://www.linkedin.com/in/mohd-shahbaz-5796b6127" target="_blank"><i className="lni-linkedin-original"></i></a></li>
                  </ul>
                </div>
                {/* <!-- footer content --> */}
              </div>
            </div>
          </div>
          {/* <!-- container --> */}
        </div>
        {/* <!-- footer widget --> */}
        <div className="footer-copyright pb-20">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="copyright-text text-center pt-20">
                  <p>Copyright © 2022. Portfolio Created by <a href="" rel="nofollow">me</a></p>
                </div>
                {/* <!-- copyright text --> */}
              </div>
            </div>
          </div>
          {/* <!-- container --> */}
        </div>
        {/* <!-- footer widget --> */}
      </footer>

      {/* <!--====== FOOTER PART ENDS ======--> */}

      {/* <!--====== BACK TOP TOP PART START ======--> */}

      <a href="#" className="back-to-top"><i className="lni-chevron-up"></i></a>

      {/* <!--====== BACK TOP TOP PART ENDS ======--> */}

    </div>
  )
}

export default FooterComponent