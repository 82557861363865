import React from 'react'
import WorkPopup from './childSection/WorkPopup'


const slideImages = [
    {
        url: 'assets/images/work/khushiiwebsite.png',
        caption: 'khushi website',
        id:'khushi',
        websiteURL: 'https://khushii.org/'
    },
    {
        url: 'assets/images/work/vss.png',
        caption: 'VSS website',
        id:'vss',
        websiteURL:'https://www.vasantvalley.org/'


    },
    {
        url: 'assets/images/work/hps.png',
        caption: 'Handa Plastic Surgery',
        id:'hps',
        websiteURL:'https://handaplasticsurgery.com/'


    },
];

const MyWorkComponent = () => {
    const handleClick = (e, id) => {
        e.preventDefault();
        console.log(' clicked ', id)
        document.querySelector(`.popupImg-${id} img`).click()
    }
    return (
        <>
            {/* <!--====== WORK PART START ======--> */}

            <section id="work" className="work-area pt-125 pb-130">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="section-title pb-25">
                                <h2 className="title">My Recent Works</h2>

                            </div>
                            {/* <!-- section title --> */}
                        </div>
                    </div>
                    {/* <!-- row --> */}
                    <div className="row">

                        {slideImages.map((slideImage, index) => (
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-work text-center mt-30">
                                    <div class="work-overlay">
                                        <div class="work-content">
                                            <h3 class="work-title">{slideImage.caption}</h3>
                                            <ul>
                                                <li><a class="image-popup" onClick={(e) => handleClick(e,slideImage.id)} href={slideImage.url}><i class="lni-plus"></i></a></li>
                                                <li><a target="_blank" href={slideImage.websiteURL}><i class="lni-link"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <WorkPopup
                                        smallImg={slideImage.url}
                                        largeImg={slideImage.url}
                                        alt={slideImage.caption}
                                        id={slideImage.id}
                                        key={index}
                                    />



                                </div>
                                {/* <!-- single work --> */}
                            </div>
                        ))}





                    </div>
                    {/* <!-- row --> */}
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="work-more text-center mt-50">
                                <a className="main-btn" href="#">more works</a>
                            </div>
                            {/* <!-- work more --> */}
                        </div>
                    </div>
                    {/* <!-- row --> */}
                </div>
                {/* <!-- container --> */}
            </section>

            {/*  // <!--====== WORK PART ENDS ======--> */}
        </>
    )
}

export default MyWorkComponent