import './App.css';
import MainComponent from './components/Main/MainComponent';

function App() {
  return (
   <div className='app'>
    <MainComponent />
   </div>
  );
}

export default App;
