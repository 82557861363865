import React from 'react'

const AboutComponent = () => {
  const skills = ['php', 'laravel', 'reactjs','nodejs', 'angular', 'wordpress', 'bootstrap'];
  const getFirstLetter = (string) => {
    let data = '';
    for( let i = 0; i < string.length; i++) {
      if( i == 0 ){
        data = string[i].toUpperCase();
      }else{
        data = string[i] + data;
      }


    }
    // string.forEach( (letter, i) => {
    //   if( i !== 0 ){
    //     data = data.letter
    //   }
    // });
    return data;
  }
  return (
    <>
         {/* <!--====== ABOUT PART START ======--> */}

      <section id="about" className="about-area pt-125 pb-130">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center">
                <h2 className="title">About Me</h2>
                <p>I am a Laravel, reactjs and Nodejs Developer, i have 5+ years experience as a software engineer.</p>
              </div>
              {/* <!-- section title --> */}
            </div>
          </div>
          {/* <!-- row --> */}
          <div className="row">
            <div className="col-lg-7">
              <div className="about-content mt-50">
                <h5 className="about-title">Hi There! I'm Mohd Shahbaz</h5>
                <p>I am a Laravel, reactjs and Nodejs Developer i have 5+ years experience as a software engineer. I have worked on 20+ projects in differents technologies like laravel, reactjs, angular, nodejs, wordpress, shopify, magento</p>
                <ul className="clearfix">
                  <li>
                    <div className="single-info d-flex align-items-center">
                      <div className="info-icon">
                        <i className="lni-calendar"></i>
                      </div>
                      <div className="info-text">
                        <p><span>Date of birth:</span> 24-Sep-1994</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="single-info d-flex align-items-center">
                      <div className="info-icon">
                        <i className="lni-envelope"></i>
                      </div>
                      <div className="info-text">
                        <p><span>Email:</span> shahbaz342s@gmail.com</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="single-info d-flex align-items-center">
                      <div className="info-icon">
                        <i className="lni-phone-handset"></i>
                      </div>
                      <div className="info-text">
                        <p><span>Phone:</span> +91-8604046337</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="single-info d-flex align-items-center">
                      <div className="info-icon">
                        <i className="lni-map-marker"></i>
                      </div>
                      <div className="info-text">
                        <p><span>Location:</span> New Delhi</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              {/* <!-- about content --> */}
            </div>
            <div className="col-xl-4 offset-xl-1 col-lg-4">
              <div className="about-skills pt-25">

                { skills.map( (item,key) => {
                  return <div className="skill-item mt-25" key={key}>
                  <div className="skill-header">
                    <h6 className="skill-title"> { item } </h6>
                    <div className="skill-percentage" style={{display:'none'}}>
                      <div className="count-box counted">
                        <span className="counter">70</span>
                      </div>

                    </div>
                  </div>
                  <div className="skill-bar">
                    <div className="bar-inner">
                      <div className="bar progress-line" data-width="100" style={{width:'100%'}}></div>
                    </div>
                  </div>
                </div>
                })}
                
                {/* <!-- skill item --> */}


              </div>
              {/* <!-- about skills --> */}
            </div>
          </div>
          {/* <!-- row --> */}
        </div>
        {/* <!-- container --> */}
      </section>

      {/* <!--====== ABOUT PART ENDS ======--> */}
    </>
  )
}

export default AboutComponent