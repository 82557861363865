import React from 'react'
import HeaderComponent from './../Header/HeaderComponent';
import FooterComponent from './../Footer/FooterComponent';
import AboutComponent from './section/AboutComponent';
import CallToAction from './section/CallToAction';
import MyWorkComponent from './section/MyWorkComponent';
import ContactComponent from './section/ContactComponent';

const MainComponent = () => {
  return (
    <div>
      <HeaderComponent />

      <AboutComponent />

      <CallToAction />

      <MyWorkComponent />


      <ContactComponent />

      <FooterComponent />
    </div>
  )
}

export default MainComponent