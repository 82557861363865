import axios from 'axios';
import React, { useState } from 'react'
const APIURL = 'https://contact-me-service.onrender.com/api/v1/contact/create'
const ContactComponent = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    
    const [nameErr, setNameErr] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [messageErr, setMessageErr] = useState('');

    const [loader, setLoader] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');

    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    }
    
    const handleChangeEmail = (event) => {
        if (!isValidEmail(event.target.value)) {
            setEmailErr('Email is invalid');
        } else {
            setEmailErr('');
        }
    
        setEmail(event.target.value);
      };

    const handleSubmit = (e) => {
        setSuccessMsg('')
        setNameErr('')
        setEmailErr('')
        setMessageErr('')
        e.preventDefault();
        setLoader(true);
        console.log(name, email, message);
        console.log('contact form submitted')
        if( email && name && message){
            setLoader(true);
            contactApiCall({email, name, message})
        }else{
            if( !email ){
                setEmailErr('this field is required')
            }if( !name ){
                setNameErr('this field is required')
            }if( !message ){
                setMessageErr('this field is required')
            }
            setLoader(false);

        }
    }
    const resetConctactForm = () => { 
        document.getElementById("contact-form").reset();
    }
    const contactApiCall = async (payload) => {
        try{
            const {data} = await axios.post(APIURL, payload)
            console.log('contact api called with data', data)
            if( data.status == true ){
                setLoader(false);
                resetConctactForm();
                setName('')
                setEmail('')
                setMessage('')
                setSuccessMsg('Your query has been sent successfully');
            }
        }catch(err){
            console.log(err)
        }
    }
    return (
        <>
            {/* <!--====== CONTACT PART START ======--> */}

            <section id="contact" className="contact-area pt-125 pb-130 gray-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="section-title text-center pb-25">
                                <h2 className="title">Get In Touch</h2>
                                <p>If you have any query feel free to contact me.</p>
                            </div>
                            {/* <!-- section title --> */}
                        </div>
                    </div>
                    {/* <!-- row --> */}
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-7">
                            <div className="contact-box text-center mt-30">
                                <div className="contact-icon">
                                    <i className="lni-map-marker"></i>
                                </div>
                                <div className="contact-content">
                                    <h6 className="contact-title">Address</h6>
                                    <p>New Delhi</p>
                                </div>
                            </div>
                            {/* <!-- contact box --> */}
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-7">
                            <div className="contact-box text-center mt-30">
                                <div className="contact-icon">
                                    <i className="lni-phone"></i>
                                </div>
                                <div className="contact-content">
                                    <h6 className="contact-title">Phone</h6>
                                    <p>+91 8604 046337</p>
                                </div>
                            </div>
                            {/* <!-- contact box --> */}
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-7">
                            <div className="contact-box text-center mt-30">
                                <div className="contact-icon">
                                    <i className="lni-envelope"></i>
                                </div>
                                <div className="contact-content">
                                    <h6 className="contact-title">Email</h6>
                                    <p>shahbaz342s@gmail.com</p>
                                </div>
                            </div>
                            {/* <!-- contact box --> */}
                        </div>
                    </div>
                    {/* <!-- row --> */}
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="contact-form pt-30">

                                {/* contact form  */}
                                <form id="contact-form" method="post" onSubmit={handleSubmit}>
                                    <div className="single-form">
                                        <input type="text" name="name" id="name" className="name" placeholder="Name" onChange={(e) => setName(e.target.value)} />
                                        <span className="input_err" id="name_err">
                                            {nameErr ? nameErr : ''}
                                        </span>
                                    </div>
                                    {/* <!-- single form --> */}
                                    <div className="single-form">
                                        <input type="email" name="email" id="email" placeholder="Email" onChange={handleChangeEmail} />
                                        <span className="input_err" id="email_err">
                                            {emailErr ? emailErr : ''}
                                        </span>
                                    </div>
                                    {/* <!-- single form --> */}
                                    <div className="single-form">
                                        <textarea name="message" id="msg" placeholder="Message" onChange={(e) => setMessage(e.target.value)}></textarea>
                                        <span className="input_err" id="msg_err">
                                            {messageErr ? messageErr : ''}
                                        </span>
                                    </div>
                                    {/* <!-- single form --> */}
                                    <p className="form-message"></p>
                                    <div className="single-form">
                                        <button className="main-btn" id="submit_btn" type="submit">
                                            {loader ? 'Please wait...' : 'Send Message'}
                                        </button>
                                    </div>
                                    {/* <!-- single form --> */}
                                    
                                </form>
                                {successMsg ? <div id="form-status">{successMsg}</div> : ''}
                                {/* contact form end */}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact-map mt-60">
                                <div className="gmap_canvas">

                                    {/* <!--<iframe id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14013.421094472433!2d77.3020569!3d28.5891169!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8d0cf5c34a0963e1!2sNew%20Ashok%20Nagar!5e0!3m2!1sen!2sin!4v1622797945798!5m2!1sen!2sin" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>--> */}
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.7059838094156!2d77.25191525000001!3d28.54855585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce3cf862f4343%3A0x646a6f8f0f3791eb!2sNEHRU%20PLACE-2%2C%20Nehru%20Place%2C%20New%20Delhi%2C%20Delhi%20110019!5e0!3m2!1sen!2sin!4v1653128617774!5m2!1sen!2sin" width="600" height="450" style={{border:'0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

                                </div>
                            </div>
                            {/* <!-- contact map --> */}
                        </div>
                    </div>
                    {/* <!-- row --> */}
                </div>
                {/* <!-- container --> */}
            </section>


            {/* <!--====== CONTACT PART ENDS ======--> */}
        </>
    )
}

export default ContactComponent